import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useStoreActions } from 'store/hooks';
import store from 'store/index';
import { handleUtmUrl } from 'lib/urlUtils';

function IndexPage() {
  const router = useRouter();
  const { getUser } = useStoreActions((actions) => actions.user);

  useEffect(() => {
    async function redirectUser() {
      await getUser();
      // don't use hooks here to avoid rerenders and api calls
      const { user } = store.getState().user;
      if (user) {
        return router.replace('/myboards');
      }

      return router.replace(handleUtmUrl('/login', router.query));
    }
    if (router.query) redirectUser();
  }, [router.query]);

  return null;
}

export default IndexPage;
